import i18n from '@/config/i18n'

export const NotificationMixin = {
    methods: {
        openToast(message, type) {
            this.$buefy.toast.open({
                message,
                type: `is-${ type }`,
                duration: 4000
            })
        },
        notifyError(message = i18n.t('Something went wrong. Please reload the page.')) {
            // TODO: implement nice DRF error display when an error object is passed
            this.openToast( typeof message !== 'string' ? JSON.stringify(message) : message , 'danger')
        },
        notifySuccess(message = i18n.t('Successfully saved.')) {
            this.openToast(message, 'success')
        },
        notifyWarning(message) {
            this.openToast(message, 'warning')
        },
        notifyInfo(message) {
            this.openToast(message, 'info')
        }
    }
}


export const DeletionConfirmationMixin = {
    methods: {
        confirmDeletion(callback, object) {
            this.$buefy.dialog.confirm({
                title: this.$t('Heads up!'),
                message: this.$t('Are you sure you want to <b>delete</b> this object? This action '
                    + 'cannot be undone.'),
                confirmText: this.$t('Delete'),
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => callback(object)
            })
        }
    }
}

export const DiscardChangesConfirmationMixin = {
    methods: {
        confirmDiscardingChanges(callback) {
            this.$buefy.dialog.confirm({
                title: this.$t('Heads up!'),
                message: this.$t('You have <b>unsaved changes</b>. Are you sure you want to quit? '
                    + 'This action cannot be undone.'),
                confirmText: this.$t('Quit anyway'),
                type: 'is-danger',
                hasIcon: true,

                onConfirm: () => callback()
            })
        }
    }
}
