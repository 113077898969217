<template>
    <div>
        <section class="section">
            <div class="container">
                <div class="introtext">
                    <BulbIcon />
                    {{ introtext }}
                </div>
                <div class="columns">
                    <div class="column is-3">
                        <b-field expanded>
                            <b-datepicker
                                v-model="filters.from"
                                :placeholder="$t('From')"
                                icon-right="calendar-today" />
                            <p v-if="filters.from" class="control">
                                <button class="button" @click="filters.from = null">
                                    <a class="delete" />
                                </button>
                            </p>
                        </b-field>
                    </div>
                    <div class="column is-3">
                        <b-field expanded>
                            <b-datepicker
                                v-model="filters.to"
                                :placeholder="$t('To')"
                                icon-right="calendar-today" />
                            <p v-if="filters.to" class="control">
                                <button class="button" @click="filters.to = null">
                                    <a class="delete" />
                                </button>
                            </p>
                        </b-field>
                    </div>
                    <div class="column is-6">
                        <b-field expanded>
                            <b-select
                                v-model="filters.formType"
                                :placeholder="$t('Select a form type')" expanded>
                                <option
                                    v-for="formCategory in formCategories"
                                    :key="formCategory.type"
                                    :value="formCategory.type">
                                    {{ formCategory.label }}
                                </option>
                            </b-select>
                            <p v-if="filters.formType" class="control">
                                <button class="button" @click="filters.formType = null">
                                    <a class="delete" />
                                </button>
                            </p>
                        </b-field>
                    </div>
                </div>
                <b-field expanded class="coming-soon">
                    <b-input v-model.trim="filters.q" :placeholder="$t('Search')"
                             icon="magnify" />
                </b-field>
                <div class="mb-8">
                    <button :disabled="disableMerge"
                            class="button mr-4"
                            @click="confirmMerge">
                        <span>{{ $t('Merge PDF') }}</span>
                    </button>
                    <template v-if="!hasRole('display')">
                        <button class="button"
                                @click="isNewArchiveModalActive = true">
                            <span>{{ $t('Upload document') }}</span>
                        </button>
                    </template>
                    <div v-if="disk_quota" class="is-pulled-right disk-quota">
                        {{ disk_quota.archive|diskQuota }}
                    </div>
                </div>
                <router-view />
            </div>
            <b-modal :active.sync="isNewArchiveModalActive"
                     :can-cancel="['escape', 'outside', 'button']">
                <ArchiveUploadCard @archive="saveArchive($event)"
                                   @cancel="isNewArchiveModalActive = false" />
            </b-modal>
        </section>
    </div>
</template>

<script>
    import isEmpty from 'lodash/isEmpty'

    import { mapActions, mapGetters, mapState } from 'vuex'
    import ArchiveUploadCard from './components/ArchiveUploadCard'
    import { NotificationMixin } from '@/config/mixins'
    import { hasRole } from '@/modules/Auth/helpers'
    import { getDiskQuota } from '@/services/company.service'
    import BulbIcon from '@/components/icons/Bulb'

    export default {
        components: {
            BulbIcon,
            ArchiveUploadCard
        },
        mixins: [ NotificationMixin ],
        data() {
            return {
                isNewArchiveModalActive: false,
                filters: {},
                // eslint-disable-next-line max-len
                introtext: this.$t('Archive intro text. Dapibus maecenas lorem convallis lobortis tortor nec, conubia dictum penatibus aliquam nostra vulputate, metus sociosqu porta non semper.'),
                disk_quota: null
            }
        },
        computed: {
            ...mapState('forms', [ 'formCategories' ]),
            ...mapState('archives', [ 'archives' ]),
            ...mapGetters('archives', [ 'mergeLink' ]),
            disableMerge() {
                return isEmpty(this.filters) || Object.values(this.filters).every(f => f === null) || isEmpty(this.archives)
            }
        },
        watch: {
            filters: {
                handler(newFilters) {
                    this.fetch(newFilters)
                },
                deep: true
            }
        },
        mounted() {
            this.fetch()
            this.$store.dispatch('forms/fetch')
            getDiskQuota().then(r => this.disk_quota = r)
        },
        methods: {
            ...mapActions('archives', [ 'fetch', 'save', 'merge' ]),
            hasRole,
            saveArchive(archive) {
                this.save(archive)
                    .then(() => {
                        this.filters = {}
                        this.notifySuccess()
                        getDiskQuota().then(r => this.disk_quota = r)
                    })
                    .catch(e => this.notifyError(e.response.data))
                this.isNewArchiveModalActive = false
            },
            confirmMerge() {
                this.$buefy.dialog.confirm({
                    title: this.$t('Heads up!'),
                    message: this.$t('Are you sure you want to merge {count} archives? This may take some time.', { count: this.archives.length }),
                    confirmText: this.$t('Merge'),
                    cancelText: this.$t('Cancel'),
                    type: 'is-info',
                    hasIcon: true,
                    onConfirm: () => window.open(this.mergeLink, '_blank')
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "~@/assets/vars";

    .columns {
        justify-content: space-between;
    }

</style>
