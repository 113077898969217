<template>
    <div class="card has-text-centered ">
        <header class="card-header">
            <button
                type="button"
                class="modal-close is-large"
                style="position:absolute; top: 1px; right: 4px;"
                @click="$parent.close()" />
            <p class="card-header-title is-centered">{{ $t('Upload form to archive') }}</p>
        </header>
        <div class="card-content column is-half is-offset-one-quarter">
            <br>
            <b-field class="has-addons" position="is-centered">
                <b-select v-model="formType" :placeholder="$t('Select a form type')" expanded>
                    <option
                        v-for="formCategory in formCategories"
                        :key="formCategory.type"
                        :value="formCategory.type">
                        {{ formCategory.label }}
                    </option>
                </b-select>
            </b-field>
            <template v-if="formType">
                <b-field position="is-centered">
                    <b-input v-model="filename" :placeholder="$t('File name')" expanded />
                </b-field>
                <br>
                <b-field v-if="!file">
                    <b-upload v-model="file"
                              drag-drop>
                        <section class="section">
                            <div class="content has-text-centered">
                                <p>
                                    <DocumentUploadIcon />
                                </p>
                                <p class="is-size-6"><b>{{ $t('Please upload a document') }}</b></p>
                            </div>
                        </section>
                    </b-upload>
                </b-field>
                <b-message v-else type="is-success" size="is-small">
                    <p>{{ $t('Upload completed!') }}</p>
                </b-message>
            </template>
            <br>
            <b-button class="is-primary" :disabled="!formType"
                      @click="emitArchive">
                <span>{{ $t('Archive') }}</span>
            </b-button>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import { filesize, fileToBase64 } from '@/utils'
    import { NotificationMixin } from '@/config/mixins'
    import DocumentUploadIcon from '@/components/icons/DocumentUpload'
    import { MAX_UPLOAD_FILE_SIZE } from '@/constants'


    export default {
        components: { DocumentUploadIcon },
        mixins: [
            NotificationMixin
        ],
        data() {
            return { file: null, filename: null, formType: null }
        },
        computed: { ...mapState('forms', [ 'formCategories' ]) },
        watch: {
            async file(file) {
                if (!file || !this.formType)
                    return

                if (file.type !== 'application/pdf') {
                    this.notifyError(this.$t('Only .pdf files are allowed.'))
                    this.file = null
                    return
                } else if (file.size && file.size > MAX_UPLOAD_FILE_SIZE) {
                    this.notifyError(this.$t('File size is too big. Max allowed size is {filesize}.', { filesize: filesize(MAX_UPLOAD_FILE_SIZE) }))
                    this.file = null
                    return
                }
                this.fileName = await this.getSuggestedFilename(this.formType)
            },
            async formType() {
                this.filename = await this.getSuggestedFilename(this.formType)
            }
        },
        created() {
            this.$store.dispatch('forms/fetch')
        },
        methods: {
            ...mapActions('archives', [ 'getSuggestedFilename' ]),
            async emitArchive() {
                try {
                    const b64File = await fileToBase64(this.file)
                    this.$emit('archive', {
                        fileName: this.filename,
                        file: b64File,
                        formType: this.formType
                    })
                } catch (e) {
                    this.notifyError(this.$t('This file seems corrupt.'))
                }
            }
        }
    }
</script>

<style lang="scss">
</style>
